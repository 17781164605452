import { useProjects } from "../../hooks/useProjects";
import { useSnackbar } from "notistack";
import { api } from "../../util/api";
import { navigate } from "@reach/router";
import { useTeam } from "../../hooks/useTeam";
import camelToTitle from "../../util/case";
import { useState } from "react";

import { statuses } from "./Notes";
import { ButtonTW } from "../../comp/tw/Button";

const Projects = () => {
  let { projects, isLoading, error } = useProjects();
  const { team, isLoading: teamIsLoading, error: teamError } = useTeam();
  const [projectFilter, setProjectFilter] = useState(
    team.permissions.includes("createProject") ? "all" : "ready"
  );

  const { enqueueSnackbar } = useSnackbar();

  if (isLoading || teamIsLoading) {
    return <div className="spinner" />;
  }

  if (error || teamError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  const createProject = async () => {
    enqueueSnackbar("Creating Project...");

    const addProject = await api("POST", `/project`, {
      name: new Date().toLocaleString(),
    });

    navigate(`/project/${addProject.id}`);
    enqueueSnackbar("Created", { variant: "success" });
  };

  // console.log(projectFilter);
  projects =
    projectFilter === "all"
      ? projects
      : projects.filter((project) => {
          return project.status === projectFilter;
        });

  // sort projects by date
  projects = projects.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-3xl font-bold mb-4">Projects</h1>
        <ButtonTW
          // type="primary"
          label="Add"
          // size="small"
          onClick={createProject}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="filterType"
          className="block text-sm font-medium text-gray-700"
        >
          Filter by Status:
        </label>
        <select
          id="filterType"
          value={projectFilter}
          onChange={(e) => setProjectFilter(e.target.value)}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 rounded-md"
        >
          {statuses.map((status) => (
            <option key={status} value={status}>
              {camelToTitle(status)}
            </option>
          ))}
          <option key={"all"} value={"all"}>
            {camelToTitle("all")}
          </option>
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {projects.map((project) => {
          return <ProjectCard key={project.id} project={project} />;
        })}
      </div>
    </>
  );
};

export function sumNumbers(arr) {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    const num = parseFloat(arr[i]);
    if (!isNaN(num)) {
      sum += num;
    }
  }
  return sum;
}

const ProjectCard = ({ project, team }) => {
  const getStyle = (status) => {
    switch (status) {
      case "notQuoted":
        return "bg-red-100";
      case "quoted":
        return "bg-blue-100";
      case "noAnswer":
        return "bg-zinc-100";
      case "onHold":
        return "bg-yellow-100";
      case "ready":
        return "bg-green-100";
      case "done":
        return "bg-white";
      default:
        return "bg-white";
    }
  };

  return (
    <div
      onClick={() => {
        navigate(`/project/${project.id}`);
      }}
      className={`${getStyle(
        project.status
      )} shadow-md rounded-lg p-6 hover:shadow-lg hover:scale-110`}
    >
      <div className="flex justify-between">
        <h2 className="text-xl font-bold mb-2">
          {project.clientFirstName ? project.clientFirstName : "N/A"}{" "}
          {project.clientLastName}
        </h2>
        <h2 className="text-xl font-bold mb-2">
          {project.lengths
            ? `${sumNumbers(Object.values(project.lengths))} ft`
            : "N/A"}{" "}
        </h2>
      </div>
      <h2 className="text-xl font-bold mb-2">
        {camelToTitle(project.status ? project.status : "noStatus")}
      </h2>
      <p>{project.address ? project.address : "N/A"}</p>
      <p>{project.city ? project.city : "N/A"}</p>
    </div>
  );
};

export default Projects;

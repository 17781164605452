import React from "react";
import { useTeam } from "../../hooks/useTeam";
import { PublicTeamComp } from "../../PublicTeam";
import { TextFieldTWSolo } from "../../comp/tw/TextField";
import { Colorful } from "@uiw/react-color";

import { usePublicTeam } from "../../hooks/usePublicTeam";
import { useDebouncedUpdate } from "../../util/debounce";

const PublicTeamPage = () => {
  const { team, isLoading, error, mutate } = useTeam();
  const {
    data,
    isLoading: publicIsLoading,
    error: publicError,
    mutate: publicMutate,
  } = usePublicTeam(team.team.id);

  const debounceUpdate = useDebouncedUpdate();

  const changePercentage = async (e) => {
    mutate(
      {
        ...team,
        team: { ...team.team, percentageRange: e.target.value },
      },
      { revalidate: false }
    );

    debounceUpdate({
      method: "PUT",
      path: `/team/${team.team.id}`,
      data: { percentageRange: e.target.value },
      label: "Percentage Range",
    });
    publicMutate(
      {
        ...data,
        team: { ...data.team, percentageRange: e.target.value },
      },
      { revalidate: false }
    );
  };
  // await
  // await mutate();

  const changeColorCode = (colorCode) => {
    mutate(
      {
        ...team,
        team: { ...team.team, colorCode },
      },
      { revalidate: false }
    );
    debounceUpdate({
      method: "PUT",
      path: `/team/${team.team.id}`,
      data: { colorCode },
      label: "Color Code",
    });

    publicMutate(
      {
        ...data,
        team: { ...data.team, colorCode },
      },
      { revalidate: false }
    );
  };

  if (isLoading || error || publicIsLoading || publicError) {
    return <div className="spinner" />;
  }

  return (
    <div>
      <h1 className="text-5xl font-semibold mb-5">Public Page Setup</h1>

      <TextFieldTWSolo
        label="Percentage Range:"
        onChange={changePercentage}
        value={team?.team?.percentageRange}
        type="number"
      />
      <div className="flex justify-around gap-x-2 items-center">
        <TextFieldTWSolo
          label="Color Code:"
          onChange={(e) => changeColorCode(e.target.value)}
          value={team.team.colorCode}
        />
        <Colorful
          disableAlpha={true}
          color={team.team.colorCode}
          onChange={(color) => {
            changeColorCode(color.hex);
          }}
        />
      </div>

      <div className="mb-10">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Embed Code:
          </label>
          <textarea
            readOnly
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md bg-zinc-300"
            value={`<iframe src="${window.location.origin}/team/${team.team.id}" width="100%" height="100%"></iframe>`}
            onClick={() => {
              navigator.clipboard.writeText(
                `<iframe src="${window.location.origin}/team/${team.team.id}" width="100%" height="100%"></iframe>`
              );
              alert("Embed code copied to clipboard!");
            }}
          />
        </div>
        * You may have to edit the height and width of the iframe to fit your
        website
      </div>

      <PublicTeamComp teamId={team.team.id} />
    </div>
  );
};

export default PublicTeamPage;

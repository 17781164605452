import React from "react";
import { usePublicTeam } from "./hooks/usePublicTeam";
import { useParams } from "@reach/router";
import { getSections } from "./util/fence";
import { TextFieldTWSolo } from "./comp/tw/TextField";

// a586b181-7b03-4063-b7b4-a2a9c2f374a3
// #fdd835
const costPerFt = ({
  lengthOfSection,
  serviceTax,
  materials,
  materialTax,
  labourCostPerFt,
  totalLinearFt,
  materialMultiplier,
}) => {
  // postMaterials = materials.map(material => {
  //     if (material.category === "post") {

  //     }
  // })
  materialTax = parseFloat(materialTax);
  serviceTax = parseFloat(serviceTax);
  totalLinearFt = parseFloat(totalLinearFt);
  labourCostPerFt = parseFloat(labourCostPerFt);
  materialMultiplier = parseFloat(materialMultiplier);
  // console.log(materialTax, serviceTax);

  const sections = getSections(totalLinearFt, lengthOfSection);
  const posts = sections + 1;
  const postMaterials = materials.filter(
    (material) => material.fenceCategory === "post"
  );
  const panelMaterials = materials.filter(
    (material) => material.fenceCategory === "panel"
  );

  const postCost = postMaterials.reduce((acc, material) => {
    return (
      acc + parseFloat(material.price) * posts * parseFloat(material.quantity)
    );
  }, 0);

  const panelCost = panelMaterials.reduce((acc, material) => {
    return (
      acc +
      parseFloat(material.price) * sections * parseFloat(material.quantity)
    );
  }, 0);

  // console.log(postCost, panelCost);
  // console.log(materialTax);

  let materialCost =
    postCost * (materialTax / 100 + 1) + panelCost * (materialTax / 100 + 1);

  materialCost = materialCost * (materialMultiplier / 100 + 1);

  // console.log("Material Cost");
  // console.log(materialCost);
  const labourCost = labourCostPerFt * totalLinearFt;
  // console.log(labourCost);
  const totalCost = (materialCost + labourCost) * (serviceTax / 100 + 1);

  return { totalCost, costPerFt: totalCost / totalLinearFt };
};

export function PublicTeam() {
  const { teamId } = useParams();
  return <PublicTeamComp teamId={teamId} />;
}

export const PublicTeamComp = ({ teamId }) => {
  const { data, isLoading, error } = usePublicTeam(teamId);
  const [totalLinearFt, setTotalLinearFt] = React.useState(16);
  const [isSelected, setIsSelected] = React.useState(false);
  //   console.log(error);
  //   console.log(isLoading);
  if (isLoading || error) {
    return <div className="spinner" />;
  }

  data.publicDesigns = data?.publicDesigns?.map((design) => {
    let cost = costPerFt({
      lengthOfSection: design.lengthOfSection,
      totalLinearFt,
      materials: design.materials,
      labourCostPerFt: data.team.labourCostPerFt,
      serviceTax: data.team.serviceTax,
      materialTax: data.team.materialTax,
      materialMultiplier: data.team.materialMultiplier,
    });

    return { ...design, ...cost };
  });
  // sort by name
  data.publicDesigns = data.publicDesigns.sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  //   costPerFt({lengthOfSection: data.team.})
  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-1">
        {data.publicDesigns.map((d) => {
          return (
            <div
              className={`gap-x-1 m-1 border-solid rounded-lg border-4 p-2 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg`}
              key={d.id}
              style={{
                borderColor: isSelected === d.id ? data.team.colorCode : "",
              }}
              onClick={() => setIsSelected(d.id)}
            >
              <div className="text-center font-semibold text-xl">
                <h1>{d.name}</h1>
              </div>

              <div className="h-64 p-2 flex flex-col justify-center">
                <img alt={d.name} src={d.image} className="max-h-64 mx-auto" />
              </div>
            </div>
          );
        })}
      </div>

      <div className="text-center grid grid-cols-1 md:grid-cols-2 gap-x-5">
        <div>
          <TextFieldTWSolo
            value={totalLinearFt}
            label={"Total Linear Ft:"}
            type="number"
            onChange={(e) => setTotalLinearFt(e.target.value)}
            // className="w-28"
          />
        </div>

        <div>
          <TextFieldTWSolo
            value={
              isSelected && totalLinearFt
                ? `$${(
                    data.publicDesigns.find((d) => d.id === isSelected)
                      ?.totalCost *
                    (1 - parseFloat(data.team.percentageRange) / 100)
                  ).toFixed(2)} - $${(
                    data.publicDesigns.find((d) => d.id === isSelected)
                      ?.totalCost *
                    (parseFloat(data.team.percentageRange) / 100 + 1)
                  ).toFixed(2)}`
                : "Please Select A Fence Design and Enter Total Linear Ft"
            }
            label={"Cost:"}
            // type="number"
            disabled
            onChange={(e) => setTotalLinearFt(e.target.value)}
          />
        </div>
      </div>

      {/* <TextFieldTWSolo
        value={data.publicDesigns
          .find((d) => d.id === isSelected)
          ?.costPerFt.toFixed(2)}
        label={"Cost ($):"}
        type="number"
        disabled
        onChange={(e) => setTotalLinearFt(e.target.value)}
      /> */}
    </div>
  );
};
// const Designs

export default PublicTeam;

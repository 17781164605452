import { useRef } from "react";
import { api } from "./api";
import { useSnackbar } from "notistack";

export const useDebouncedUpdate = () => {
  const timeoutRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const debouncedUpdate = async ({ method, path, data, label }) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
      enqueueSnackbar(`${label} Updating`);
      await api(method, path, data);
      //   await api("PUT", `${updatePath}/${team.team.id}`, {
      //     [updateField]: value,
      //   });
      enqueueSnackbar(`${label} Saved`, { variant: "success" });
    }, 1000);
  };

  return debouncedUpdate;
};

import { Router, useMatch } from "@reach/router";
import Chain from "./Chain";
import ButtonAppBar from "./comp/NavBar";
import { useTeam } from "./hooks/useTeam";
import Design from "./pages/Design/Design";
import Project from "./pages/project/Project";
import Projects from "./pages/project/Projects";
import Team from "./pages/Team";
import AddUser from "./pages/Team/AddUser";
import PublicTeamPage from "./pages/project/PublicTeamPage";
import PublicTeam from "./PublicTeam";

export const MainRouter = () => {
  const { isLoading, error } = useTeam();
  const match = useMatch("/team/:teamId");

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error && error?.response?.data.error === "Team has no subscription") {
    window.location.href = error?.response?.data.session.url;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  if (match) {
    return (
      <Router>
        <PublicTeam path="/team/:teamId" />
      </Router>
    );
  }

  return (
    <>
      <ButtonAppBar />
      <div className="bg-zinc-100 min-h-screen">
        <div className="container mx-auto py-8 px-2 ">
          <Router>
            {/* <Dashboard path="/" /> */}
            <Team path="/team" />

            <PublicTeamPage path="/public" />

            <Design path="/design" />
            <Projects path="/" />
            <Project path="/project/:projectId" />

            <AddUser path="/team/add-user" />
            <Chain path="/chainlink" />
            {/* <SubscriptionPage path="/subscription" /> */}
          </Router>
        </div>
      </div>
    </>
  );
};

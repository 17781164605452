import {
  Authenticator,
  ThemeProvider,
  useTheme,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { Switch } from "./FirstLanding";
import logo from "./pictures/logo.png";
import { Router } from "@reach/router";
import PublicTeam from "./PublicTeam";

Amplify.configure({
  Auth: {
    // identityPoolId: xx-xxxx-x:xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx,
    region: "ca-central-1",
    userPoolId: "ca-central-1_1aSXOUfM1",
    userPoolWebClientId: "1f28kurgu26io05qlq8fvqq2gl",
  },
  API: {
    endpoints: [
      {
        name: "backend",
        endpoint: process.env.REACT_APP_ENDPOINT,
      },
    ],
  },
});

export function Login() {
  const { user } = useAuthenticator((context) => [context.user]);

  if (!user?.signInUserSession) {
    return (
      <Router>
        <LoginPage path="/" />
        <PublicTeam path="/team/:teamId" />
      </Router>
    );
  }

  return <Switch />;
}

const LoginPage = () => {
  const { tokens } = useTheme();

  const theme = {
    name: "Auth Example Theme",
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 32px ${tokens.colors.overlay["10"]}`,
            borderWidth: "0",
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: tokens.colors.neutral["100"],
            _hover: {
              backgroundColor: tokens.colors.neutral["90"],
            },
          },
          link: {
            color: tokens.colors.neutral["100"],
            _hover: {
              backgroundColor: tokens.colors.neutral["10"],
            },
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${tokens.colors.green["60"]}`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.neutral["80"],
            _active: {
              borderColor: tokens.colors.neutral["100"],
              color: tokens.colors.green["100"],
            },
          },
        },
      },
    },
  };

  return (
    <>
      <div className="min-h-screen flex items-center justify-center">
        <div>
          <div className="text-center">
            <img
              src={logo}
              alt="Description"
              className="w-1/2 md:w-1/3 lg:w-1/4 mx-auto"
            />
          </div>
          <ThemeProvider theme={theme}>
            <Authenticator
              loginMechanisms={["email"]}
              signUpAttributes={[
                "email",
                "given_name",
                "family_name",
                "address",
                "birthdate",
                "phone_number",
              ]}
            />
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

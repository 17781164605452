import useSWR from "swr";
import axios from "axios";
export function usePublicTeam(id) {
  const { data, error, isLoading, mutate } = useSWR(
    `/team/public/${id}`,
    (url) =>
      axios
        .get(`${process.env.REACT_APP_ENDPOINT}${url}`)
        .then((res) => res.data)
  );

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
